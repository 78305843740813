<template>
  <main class="md:p-4">
    <div class="max-w-md mx-auto">
      <sqr-error-banner :error="error" />
      <div class="bg-white md:rounded shadow p-4" v-if="relation">
        <relation-name-form :relation="relation" @field-set="fieldSet" />
        <sqr-error-banner :error="updateError" />
        <div class="flex justify-end mt-4">
          <sqr-button
            class="mr-2"
            :label="$t('buttons_cancel')"
            @click="$router.back()"
            color="white"
          />
          <sqr-button
            :label="$t('buttons_save')"
            @click="saveAndGo()"
            color="blue"
            :loading="updating"
          />
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import { mapGetters, mapMutations, mapActions } from 'vuex';
import { includes, props } from 'ramda';
import SqrButton from '@/sqrd-ui/SqrButton';
import SqrErrorBanner from '@/sqrd-ui/SqrErrorBanner';
import RelationNameForm from '../components/RelationNameForm';

export default {
  name: 'RelationName',
  components: { SqrButton, SqrErrorBanner, RelationNameForm },
  props: {
    bid: String,
    rid: String,
  },
  computed: {
    ...mapGetters('brokers', ['broker']),
    ...mapGetters('relation', ['record', 'loading', 'error']),
    relation() {
      if (!this.record) return null;
      return { ...this.record, ...this.updates };
    },
    title() {
      if (!this.relation) return '';
      return this.$t(
        'relation_title',
        Object.assign({}, this.relation, {
          status: this.$t('relation_status_' + this.relation.status),
        })
      );
    },
    subtitle() {
      if (!this.relation || !this.relation.birthDate) return '';
      return this.$t('relation_subtitle', {
        birthDate: date(this.relation.birthDate),
      });
    },
  },
  data() {
    return {
      updating: false,
      updateError: null,
      updates: {},
    };
  },
  async mounted() {
    this.sub({ path: ['brokers', this.bid], id: this.rid });
  },
  methods: {
    ...mapActions('relation', ['sub']),
    ...mapActions('updater', ['update']),
    ...mapActions('relationsIndex', ['reindex']),
    fieldSet({ field, value }) {
      this.$set(this.updates, field, value);
    },
    async saveAndGo() {
      try {
        this.updating = true;
        this.updateError = null;
        const bid = this.bid;
        const rid = this.rid;
        const name = props(['company', 'givenName', 'familyName'], this.relation)
          .filter(v => Boolean(v))
          .join(' ');
        const path = `brokers/${bid}/relations/${rid}`;
        const doc = { ...this.updates, name };
        await this.update({ path, doc });
        await this.reindex({ bid, id: rid, doc: this.relation });
        this.$router.back();
      } catch (error) {
        this.updateError = error;
      } finally {
        this.updating = false;
      }
    },
  },
  metaInfo() {
    return {
      title: this.title,
    };
  },
};
</script>
